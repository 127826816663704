
import { Injectable, inject, signal } from '@angular/core';
import { ZoomImage } from '../../types/activity.type';
import { Subject } from 'rxjs';


@Injectable({
    providedIn: 'root',
})
export class ActivityService {
    protected zommImage$ = new Subject();
    protected getDataYear$ = new Subject();

    setZoomImage(data: ZoomImage) {
        this.zommImage$.next(data);
    }

    getZoomImage() {
        return this.zommImage$;
    }

    loadDataOfYear(index: number) {
        this.getDataYear$.next(index);
    }

    getDataOfYear() {
        return this.getDataYear$;
    }
}
