import { Observable } from 'rxjs';
import { AppsManagementResponse, CMSResponse } from '../../types/common.type';
import { IAssetContact } from './contact.data';

export interface IEvent {
    id: number;
    attributes: {
        Files: File[];
        File: any;
        updatedAt: string;
        publishedAt: string;
        locale: string;
        Name: string;
        createdAt?: string;
        Description: string;
    };
    slug?: string;
    day?: number | string;
    month?: number | string;
    year?: number | string;
    date?: string;
    type?: string;
    assets?: IAssetContact[];
    published?: boolean;
    name?: string;
    content?: string;
    description?: string;
}
export interface IEventPage {
    id: number;
    attributes: {
        Banner: File[];
    };
}
export interface IParamsEventPage {
    limit?: number;
    year?: string;
    month?: string;
    locale?: string;
    offset?: number;
    type?: 'internal' | 'external' | string;
}

export abstract class EventData {
    abstract GetEventPage(
        params?: IParamsEventPage,
    ): Observable<AppsManagementResponse<IEvent[]>>;
    abstract GetDetailEvent(slug: string): Observable<CMSResponse<IEvent>>;
    abstract GetTimeLine(type: string): Observable<CMSResponse<string[]>>;
}
