import { AppsManagementResponse, CMSResponse } from '../../types/common.type';
import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
    EventData,
    IEvent,
    IEventPage,
    IParamsEventPage,
} from '@shared/data/event.data';
import { Observable } from 'rxjs';
import { environment } from '@environment/environment';
import { formatDate } from '@angular/common';
import { ValueGlobalService } from '@services/global/value.global.service';

@Injectable({
    providedIn: 'root',
})
export class EventService extends EventData {
    private HttpClient = inject(HttpClient);


    readonly #valueGlobalService = inject(ValueGlobalService);
    readonly localeDefaultService = this.#valueGlobalService.locale;

    constructor() {
        super();
    }

    override GetEventPage(
        params?: IParamsEventPage,
    ): Observable<AppsManagementResponse<IEvent[]>> {
        const { type, year, month, offset, limit } = params;
        let url = `${environment.clientUrl}/events/${type}?offset=${offset}&limit=${limit}`;
        return this.HttpClient.get<AppsManagementResponse<IEvent[]>>(url, {
            params: {
                year,
                month,
            },
        });
    }

    override GetDetailEvent(slug: string): Observable<CMSResponse<IEvent>> {
        let url = `${environment.clientUrl}/events/${slug}`;
        return this.HttpClient.get<CMSResponse<IEvent>>(url);
    }

    override GetTimeLine(type: string = "internal"): Observable<CMSResponse<string[]>> {
        let url = `${environment.clientUrl}/events/${type}/years`;
        return this.HttpClient.get<CMSResponse<string[]>>(url);
    }

    formatDate(date: string): string {
        if (this.localeDefaultService()?.code === 'vi') {
            return formatDate(date, "d, 'Tháng' M, y", 'en-US');
        } else {
            return formatDate(date, 'd, MMMM yyyy', 'en-US');
        }
    }
}
